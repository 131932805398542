import axios from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import swal from "sweetalert";
import plus from "../../assets/Icon/plus.svg";
import { BASE_URL } from "../Const/Url";
import DeleteIcon from "../CustomCommons/DeleteIcon";
import EditIcon from "../CustomCommons/EditIcon";
import Loader from "../CustomCommons/Loader";
import NextIcon from "../CustomCommons/NextIcon";
import Permission from "../CustomCommons/Permission";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import kaarujLogo from "../../assets/Icon/KAARUJ 1.png";
import {
  can_add_invoice,
  can_change_invoice,
  can_delete_invoice,
  can_view_invoice,
  has_permissions,
} from "../CustomCommons/utils";
import ViewIcon from "../CustomCommons/ViewIcon";
import * as path from "../Routes/RoutePaths";
import { showToast } from "../../utils/ToastHelper";
import { logout_func2 } from "../Const/logoutCommon";
import { ArrowDown, ArrowUp } from "phosphor-react";
import HelperExportComponent from "../../utils/HelperExportComponent";

const InvoiceList = () => {
  const [isLoading, SetisLoading] = useState(true);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const cRef = useRef();
  const [query, setQuery] = useState("");
  const [invoiceSource, setInvoiceSource] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  // ofset code
  const [totalData, setTotalData] = useState(0);
  const [items_data, setItems_data] = useState([]);
  const [total_item, setTotal_item] = useState(0);
  const [isLoading2, SetisLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 20;
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
  const [sortBy, setSortBy] = useState("");
  const [sortState, setSortState] = useState(false);

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // console.log(res.data);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  useEffect(() => {
    // if (pageNumber > 1 || query) {
    if (invoiceSource == 0 || invoiceSource == 1) {
      apiFunction(
        `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_custom=false&is_outlet=False&is_purchase_order=False&is_requisition_order=False&source=${invoiceSource}${
          sortBy ? `&ordering=${sortBy}` : ""
        }&start=${date1}&end=${date2}${deliveryStatus ? deliveryStatus : ""}${
          paymentStatus ? paymentStatus : ""
        }`
      );
    } else {
      apiFunction(
        `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&source=${invoiceSource}&query=${query}&is_custom=false&is_outlet=False&is_purchase_order=False&is_requisition_order=False&${
          sortBy ? `&ordering=${sortBy}` : ""
        }&start=${date1}&end=${date2}${deliveryStatus ? deliveryStatus : ""}${
          paymentStatus ? paymentStatus : ""
        }`
      );
      //  else {
      //   apiFunction(
      //     `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_custom=false&is_outlet=False${
      //       sortBy ? `&ordering=${sortBy}` : ""
      //     }`
      //   );
      // }
    }
    // apiFunction(
    //   `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_outlet=False&is_regular=True${
    //     sortBy ? `&ordering=${sortBy}` : ""
    //   }`
    // );

    // }
  }, [pageNumber, query, invoiceSource, sortBy, deliveryStatus, paymentStatus]);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
    // SetisLoading(false)
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
    // SetisLoading(false)
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);

  useEffect(() => {}, []);

  // ofset code

  const history = useHistory();
  const updatedPageNumber = parseInt(
    localStorage.getItem("invoicePageNbr")
      ? localStorage.getItem("invoicePageNbr")
      : 0
  );
  // const [pageNumber, setPageNumber] = useState(updatedPageNumber);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  const [invoiceList, setInvoiceList] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const handleCheckboxChange = (id) => {
    // Check if the ID is already in the state
    const isChecked = checkedIds.includes(id);

    if (isChecked) {
      // If checked, remove the ID from the state
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    } else {
      // If unchecked, add the ID to the state
      setCheckedIds([...checkedIds, id]);
    }
  };
  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked) {
      const data = items_data.map((item) => item.slug);
      setCheckedIds(data);
    } else {
      setCheckedIds([]);
    }
  };
  const invoice_choices = ["Paid", "Unpaid", "Due", "Exchanged"];
  const delivery_choices = [
    "Returned",
    "Order Placed",
    "Delivered",
    "Pending",
    "Hold",
    "Dispached",
  ];
  const delivery_type_choices = ["Regular", "Urgent"];
  const paymentType = [
    "C.O.D",
    "Card",
    "Bank Transfer",
    "bKash",
    "SSLECOMMERZ",
    "Nagad",
    "KOD",
  ];
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${month}-${day}-${year}`;
  var options = {
    day: "numeric",
    year: "numeric",
    month: "numeric",
  };
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Daily Sales Report",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });
  const handlePrints = () => {
    HandlePrint();
  };

  const getInvoiceList = () => {
    let url;
    if (date1 && date2) {
      // url = `${BASE_URL}api/v1/sales/inventory/invoice/filter/?start=${date1}&end=${date2}&is_custom=false&is_outlet=False&is_regular=True`;
      url = `${BASE_URL}api/v1/sales/inventory/invoice/?start=${date1}&end=${date2}&is_purchase_order=False&is_requisition_order=False&is_regular=true${
        sortBy ? `&ordering=${sortBy}` : ""
      }&source=${invoiceSource}&limit=${limit}${
        deliveryStatus ? deliveryStatus : ""
      }${paymentStatus ? paymentStatus : ""}`;
    } else {
      // url = `${BASE_URL}api/v1/sales/inventory/invoice/?is_custom=false&is_outlet=False&is_regular=True`;
      url = `${BASE_URL}api/v1/sales/inventory/invoice/?is_regular=true${
        sortBy ? `&ordering=${sortBy}` : ""
      }${
        invoiceSource ? `&source=${invoiceSource}` : ""
      }&limit=${limit}&start=${date1}&end=${date2}${
        deliveryStatus ? deliveryStatus : ""
      }${paymentStatus ? paymentStatus : ""}`;
    }

    axios
      .get(url)
      .then((res) => {
        setInvoiceList(res.data.data.results);
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  useEffect(() => {
    if (date1 && date2) getInvoiceList();
  }, []);
  // console.log(data);
  console.log("next", nextUrl);

  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/sales/inventory/invoice/${id}`;
        axios
          .delete(url)
          .then(() => {
            getInvoiceList();
            apiFunction(
              `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_custom=false&is_outlet=False&is_purchase_order=False&is_requisition_order=False&is_regular=True&start=${date1}&end=${date2}`
            );
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(err.request.response);
          });

        swal(" Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  const deleteDataMulti = (ids) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map((id) => {
          const url = `${BASE_URL}api/v1/sales/inventory/invoice/${id}`;
          return axios.delete(url);
        });

        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been deleted!", {
              icon: "success",
            });
            setCheckedIds([]);
          })
          .catch((err) => {
            // At least one deletion failed
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          })
          .finally(() => {
            // Call getProduct and apiFunction regardless of success or failure
            getInvoiceList();
            apiFunction(
              `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_custom=False&is_outlet=False&is_purchase_order=False&is_requisition_order=False&is_regular=True`
            );
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };

  const statusBulkChanges = (ids, name, id) => {
    swal({
      title: "Are you sure?",
      text: "Once Click, You will not be able to go back.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map((i) => {
          const url = `${BASE_URL}api/v1/sales/inventory/invoice/${i}/`;
          return axios.patch(url, { [name]: id });
        });
        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been updated!", {
              icon: "success",
            });
            setCheckedIds([]);
            // setBulkStatus([]);
          })
          .catch((err) => {
            // At least one deletion failed
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          })
          .finally(() => {
            // Call getProduct and apiFunction regardless of success or failure
            getInvoiceList();
            apiFunction(
              `${BASE_URL}api/v1/sales/inventory/invoice/?start=${date1}&end=${date2}&is_regular=true${
                sortBy ? `&ordering=${sortBy}` : ""
              }&source=${invoiceSource}&limit=${limit}${
                deliveryStatus ? deliveryStatus : ""
              }${paymentStatus ? paymentStatus : ""}`
            );
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  const keys = ["number", "prepared_by", "delivery_status", "invoice_date"];
  const getData = invoiceList.filter((p) =>
    keys.some((key) => p[key]?.toString()?.toLowerCase()?.includes(query))
  );
  // const pageCount = Math.ceil(getData.length / usersPerPage);
  const getProduct2 = () => {
    let url = `${BASE_URL}api/v1/inventory/inventory/products/filter/?start=${date1}&end=${date2}`;
    // /api/v1/inventory/inventory/products/filter/?start=02/11/2021&end=04/09/2024
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data.results);
        const result = res.data.data?.data;
        setInvoiceList(
          res.data.data?.results?.sort((a, b) => b.total_sales - a.total_sales)
        );
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        // SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };

  const generateData = () => {
    if (!date1 && !date2) {
      swal("Please select two dates");
    } else if (
      (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") ||
      (date1 === "" && date2 === "")
    ) {
      swal("Please select two dates");
    } else if (date1 === "undefined/undefined/" || date1 === "") {
      swal("Please select  start Date");
    } else if (date2 === "undefined/undefined/" || date2 === "") {
      swal("Please select  end Date");
    } else {
      getInvoiceList();
    }
  };
  useEffect(() => {
    // getInvoiceList();
    if (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") {
      swal("Please select  dates");
    }
  }, []);
  // useEffect(() => {
  const handleInvoiceSourceFilter = (e) => {
    e.preventDefault();
    const value = e.target.value;
    let url;
    if (value == 0 || value == 1) {
      url = `${BASE_URL}/api/v1/sales/inventory/invoice/?source=${value}&limit=${limit}&offset=${offset}&query=${query}${
        sortBy ? `&ordering=${sortBy}` : ""
      }&start=${date1}&end=${date2}${deliveryStatus ? deliveryStatus : ""}${
        paymentStatus ? paymentStatus : ""
      }`;
    } else if (value == 2) {
      url = `${BASE_URL}/api/v1/sales/inventory/invoice/?limit=${limit}&offset=${offset}${
        sortBy ? `&ordering=${sortBy}` : ""
      }&start=${date1}&end=${date2}${deliveryStatus ? deliveryStatus : ""}${
        paymentStatus ? paymentStatus : ""
      }`;
    }
    axios
      .get(url)
      .then((res) => {
        setInvoiceList(res.data.data.results);
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setInvoiceSource(value);
      })
      .catch((error) => console.log(error));
  };
  // }, [invoiceSource, query])

  if (!has_permissions(can_view_invoice)) {
    return <Permission />;
  }
  if (isLoading) {
    return <Loader />;
  }

  const sourceList = ["Website", "Inventory", "Admin"];

  return (
    <div>
      <div className="categorey-parent">
        <div className="cotagorey-head mb-4">
          <div>
            <h3>Invoice List </h3>
            <input
              style={{ color: "black" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              onChange={(e) => {
                const selected = 0;
                // changePage({ selected });
                setQuery(e.target.value.toLocaleLowerCase());
                localStorage.setItem("invoicePageNbr", selected);
                // (updatedPageNumber = selected)
              }}
            />
          </div>
          <div>
            <h6>Prepared from</h6>
            <select
              style={{ color: "black", width: "110px" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              value={invoiceSource}
              onChange={handleInvoiceSourceFilter}
            >
              <option value={""}>All</option>
              <option value={1}>Inventory</option>
              <option value={0}>Website</option>
            </select>
          </div>
          <div>
            <h6>Delivery Status</h6>
            <select
              style={{ color: "black", width: "120px" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              value={deliveryStatus}
              onChange={(e) => setDeliveryStatus(e.target.value)}
            >
              <option value="">All</option>
              <option value="&delivery_status=1">Order Placed</option>
              <option value="&delivery_status=0">Returned</option>
              <option value="&delivery_status=2">Delivered</option>
              <option value="&delivery_status=3">Pending</option>
              <option value="&delivery_status=4">Hold</option>
              <option value="&delivery_status=5">Dispatched</option>
            </select>
          </div>
          <div>
            <h6>Payment Status</h6>
            <select
              style={{ color: "black", width: "100px" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              value={paymentStatus}
              onChange={(e) => setPaymentStatus(e.target.value)}
            >
              <option value="">All</option>
              <option value="&payment_status=0">Paid</option>
              <option value="&payment_status=1">Unpaid</option>
              <option value="&payment_status=2">Due</option>
            </select>
          </div>
          <div className="filter_div">
            <div className="my-2 my-lg-0">
              <input
                type="date"
                format="DD-MM-YYYY"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;
                  setDate1(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
              <span className="mx-2 my-auto" placeholder="09-09-2022">
                {" "}
                To
              </span>
              <input
                type="date"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;

                  setDate2(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
            </div>
            <div
              className="button-div my-auto mx-2 "
              style={{ background: "black", cursor: "pointer" }}
              onClick={generateData}
            >
              <span> Generate</span>
            </div>
            {getData?.length > 0 && (
              <div
                className="button-div my-auto me-2 "
                style={{ background: "black", cursor: "pointer" }}
                onClick={() => handlePrints()}
              >
                <span> Print</span>
              </div>
            )}
            {/* {has_permissions(can_add_invoice) && (
              <div
                className="button-div"
                onClick={() => history.push(path.create_invoice)}
              >
                <img src={plus} alt="" />
                <span> Create Invoice</span>
              </div>
            )} */}
            <div
              className="button-div ms-2 my-auto me-2 d-none  text-white "
              style={{ background: "black", cursor: "pointer" }}
            >
              <HelperExportComponent
                url={`${BASE_URL}${"api/v1/sales/inventory/invoice/"}?export=true&limit=${limit}&offset=${offset}&query=${query}&is_custom=false&is_outlet=False&is_purchase_order=False&is_requisition_order=False&source=${invoiceSource}${
                  sortBy ? `&ordering=${sortBy}` : ""
                }&start=${date1}&end=${date2}${
                  deliveryStatus ? deliveryStatus : ""
                }`}
                fileName={"invoice_export_file.xlsx"}
                items_data={items_data}
              />
            </div>
          </div>
        </div>
        {checkedIds?.length > 0 && (
          <div className="mb-2 d-flex align-items-center gap-2">
            <button
              className="btn text-light"
              style={{
                background: "black",
                cursor: "pointer",
                maxWidth: "120px",
                color: "white",
              }}
              onClick={() => deleteDataMulti(checkedIds)}
            >
              Delete
            </button>
            <select
              style={{ color: "black", width: "120px" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              onChange={(e) =>
                statusBulkChanges(
                  checkedIds,
                  "delivery_status",
                  Number(e.target.value)
                )
              }
            >
              <option value="">Select</option>
              <option value="1">Order Placed</option>
              <option value="0">Returned</option>
              <option value="2">Delivered</option>
              <option value="3">Pending</option>
              <option value="4">Hold</option>
              <option value="5">Dispatched</option>
            </select>
            <select
              style={{ color: "black", width: "100px" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              onChange={(e) =>
                statusBulkChanges(
                  checkedIds,
                  "payment_status",
                  Number(e.target.value)
                )
              }
            >
              <option value="">Select</option>
              <option value="0">Paid</option>
              <option value="1">Unpaid</option>
              <option value="2">Due</option>
            </select>
          </div>
        )}
        {/* categorey list table */}
        <div style={{ display: "block", overflowX: "auto" }}>
          <table className="table_my">
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px", borderTopLeftRadius: "20px !important" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "110px",
                    // display:'flex',
                    // justifyContent:'center',
                    margin: "center",
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <input
                      style={{
                        margin: "0 auto",
                        opacity: "1",
                        width: "17px",
                        height: "17px",
                      }}
                      type="checkbox"
                      checked={checkedIds?.length > 0}
                      onChange={(event) => handleCheckboxChangeAll(event)}
                    />
                  </div>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  <p className="ms-3 my-auto">Invoice No</p>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "187px",
                  }}
                >
                  Prepared From
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "200px",
                  }}
                >
                  <div
                    style={{ width: "150px" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <p className="my-auto">Customer Name</p>
                    {sortBy === "-bill_to" ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // localStorage.setItem("catDeshOrdering", sortBy);
                          setSortBy("bill_to");
                          setSortState((prev) => !prev);
                        }}
                      >
                        <ArrowDown color="white" size={24} weight="bold" />
                      </div>
                    ) : (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // localStorage.setItem("catDeshOrdering", sortBy);
                          setSortBy("-bill_to");
                          setSortState((prev) => !prev);
                        }}
                      >
                        <ArrowUp color="white" size={24} weight="bold" />
                      </div>
                    )}
                  </div>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "110px",
                    padding: "0 4px",
                  }}
                >
                  <div
                    style={{ width: "90px" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <p className="my-auto">Total </p>
                    {sortBy === "-total_amount" ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // localStorage.setItem("catDeshOrdering", sortBy);
                          setSortBy("total_amount");
                          setSortState((prev) => !prev);
                        }}
                      >
                        <ArrowDown color="white" size={24} weight="bold" />
                      </div>
                    ) : (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // localStorage.setItem("catDeshOrdering", sortBy);
                          setSortBy("-total_amount");
                          setSortState((prev) => !prev);
                        }}
                      >
                        <ArrowUp color="white" size={24} weight="bold" />
                      </div>
                    )}
                  </div>
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "147px",
                  }}
                >
                  Delivery Status
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  Delivery Type
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "110px",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "160px",
                  }}
                >
                  Pathao Status
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Payment Type
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  Create On
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "165px",
                  }}
                >
                  Last Modified
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    borderTopRightRadius: "4px ",
                    width: "150px",
                  }}
                >
                  <span className="ms-4">Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {items_data.length > 0 && items_data ? (
                items_data.map((category, index) => (
                  <tr className="table-row " key={category?.slug}>
                    <td>
                      <div className="d-flex justify-content-center">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          style={{ width: "17px", height: "17px" }}
                          checked={checkedIds.includes(category.slug)}
                          onChange={() => handleCheckboxChange(category?.slug)}
                        />
                      </div>
                    </td>
                    <td>
                      <p className="ms-3 my-auto">{category.number}</p>
                    </td>
                    <td>{sourceList[category?.source]}</td>
                    <td>{category?.bill_to}</td>
                    <td>৳ {category?.total_amount}</td>
                    <td>
                      {category?.pathao_order_status === "Pickup Cancelled" ? (
                        <button className={`status1 bg-danger text-white`}>
                          Cancelled
                        </button>
                      ) : (
                        <button
                          className={`status1 ${
                            (category.delivery_status === 0 &&
                              "bg-danger text-white") ||
                            (category.delivery_status === 1 &&
                              "c5 text-white") ||
                            (category.delivery_status === 2 &&
                              "bg-success text-white ")
                          }`}
                        >
                          {delivery_choices[category.delivery_status]}
                        </button>
                      )}
                      {/* <button
                        className={`status1 ${
                          (category.delivery_status === 0 &&
                            "bg-danger text-white") ||
                          (category.delivery_status === 1 && "c5 text-white") ||
                          (category.delivery_status === 2 &&
                            "bg-success text-white ")
                        }`}
                      >
                        {delivery_choices[category.delivery_status]}
                      </button> */}
                    </td>

                    <td>
                      <button
                        className={`status1 ${
                          (category.delivery_type === 0 && "c5 text-white") ||
                          (category.delivery_type === 1 &&
                            "bg-danger text-white")
                        }`}
                      >
                        {/* {category.delivery_status === 1 ? "Delivered" : "Processing"} */}
                        {delivery_type_choices[category.delivery_type]}
                      </button>
                    </td>

                    <td className="">
                      <button
                        className={`status1 ${
                          (category.payment_status === 0 && "i1") ||
                          (category.payment_status === 1 && "c4 text-white") ||
                          (category.payment_status === 2 && "c3 text-white")
                        }`}
                      >
                        {invoice_choices[category.payment_status]}
                      </button>
                    </td>
                    <td>{category?.pathao_order_status}</td>
                    <td> {paymentType[category?.payment_type]}</td>

                    {/* <td>
                      <span className="ms-1">
                      0
                      </span>
                    </td> */}
                    <td>{category?.invoice_date}</td>
                    <td className="ms-1">{category.last_modified_by}</td>
                    <td>
                      <div className="d-flex gap-3">
                        {category?.source === 0 ? (
                          <div
                            className="edit-del blue"
                            onClick={() =>
                              // history.push(
                              //   `/sales/view-web-invoice/${category?.slug}`
                              // )
                              window.open(
                                `/sales/view-web-invoice/${category?.slug}`,
                                "_blank"
                              )
                            }
                          >
                            <ViewIcon />
                          </div>
                        ) : (
                          <div
                            className="edit-del blue"
                            onClick={() =>
                              // history.push(
                              //   `/sales/view-invoice/${category?.slug}`
                              // )
                              window.open(
                                `/sales/view-invoice/${category?.slug}`,
                                "_blank"
                              )
                            }
                          >
                            <ViewIcon />
                          </div>
                        )}

                        {has_permissions(can_change_invoice) ? (
                          <>
                            {category?.source === 0 ? (
                              <div
                                className="edit-del green"
                                onClick={() =>
                                  // history.push(
                                  //   `/sales/edit-web-invoice/${category?.slug}`
                                  // )
                                  window.open(
                                    `/sales/edit-web-invoice/${category?.slug}`,
                                    "_blank"
                                  )
                                }
                                // onClick={()=>{
                                //   showToast('error','Sorry We are fixing.')
                                // }}
                              >
                                <EditIcon />
                              </div>
                            ) : (
                              <div
                                className="edit-del green"
                                onClick={() =>
                                  // history.push(
                                  //   `/sales/edit-invoice/${category?.slug}`
                                  // )
                                  window.open(
                                    `/sales/edit-invoice/${category?.slug}`,
                                    "_blank"
                                  )
                                }
                                // onClick={()=>{
                                //   showToast('error','Sorry We are fixing.')
                                // }}
                              >
                                <EditIcon />
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            className="edit-del green"
                            onClick={() =>
                              swal("Sorry , You are not able to edit invoice.")
                            }
                          >
                            <EditIcon />
                          </div>
                        )}
                        {has_permissions(can_delete_invoice) ? (
                          <div
                            className="edit-del red"
                            onClick={() => deleteData(category?.slug)}
                          >
                            <DeleteIcon />
                          </div>
                        ) : (
                          <div
                            className="edit-del red"
                            onClick={() =>
                              swal(
                                "Sorry , You are not able to delete invoice."
                              )
                            }
                          >
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* ----------2nd Table for pdf print------*/}
        <div style={{ display: "none" }} className="pt-4">
          <table
            className="table_my pdf-table my-3 pt-4 print_table"
            ref={cRef}
          >
            <thead
              className="d-none  thed w-100 text-center me-4"
              style={{ marginTop: "20px" }}
            >
              <div className="mb-4 pb-4 d-flex justify-content-center align-items-center">
                <img className="pb-3" src={kaarujLogo} width={150} alt="" />
                <p className="mt-2 ms-4">
                  Invoice List -
                  <span className="ms-3">Print Date : {currentDate} </span>
                  <span className="ms-3">Total Item : {getData?.length}</span>
                </p>
              </div>
            </thead>
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px", borderTopLeftRadius: "20px !important" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "166px",
                  }}
                >
                  <p className="ms-3 my-auto">Invoice No</p>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "187px",
                  }}
                >
                  Prepared By
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "147px",
                  }}
                >
                  Delivery Status
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "130px",
                  }}
                >
                  Delivery Type
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Payment Type
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                  }}
                >
                  Total
                </th>
                {/* <th 
                 style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  width: "100px",
                }}>
                  Count
                </th> */}
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "130px",
                  }}
                >
                  Create On
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "110px",
                  }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {getData.length > 0 ? (
                getData.map((category, index) => (
                  <tr className="table-row " key={category?.slug}>
                    <td>
                      <p className="ms-3 my-auto">{category.number}</p>
                    </td>
                    <td>{category?.prepared_by}</td>

                    <td>
                      <button
                        className={`status1 ${
                          (category.delivery_status === 0 &&
                            "bg-danger text-white") ||
                          (category.delivery_status === 1 && "c5 text-white") ||
                          (category.delivery_status === 2 &&
                            "bg-success text-white ")
                        }`}
                      >
                        {/* {category.delivery_status === 1 ? "Delivered" : "Processing"} */}
                        {delivery_choices[category.delivery_status]}
                      </button>
                    </td>

                    <td>
                      <button
                        className={`status1 ${
                          (category.delivery_type === 0 && "c5 text-white") ||
                          (category.delivery_type === 1 &&
                            "bg-danger text-white")
                        }`}
                      >
                        {/* {category.delivery_status === 1 ? "Delivered" : "Processing"} */}
                        {delivery_type_choices[category.delivery_type]}
                      </button>
                    </td>
                    <td> {paymentType[category?.payment_type]}</td>
                    <td>৳ {category?.total_amount}</td>

                    <td>{category?.invoice_date}</td>
                    <td className="">
                      <button
                        className={`status1 ${
                          (category.payment_status === 0 && "i1") ||
                          (category.payment_status === 1 && "c4 text-white") ||
                          (category.payment_status === 2 && "c3 text-white")
                        }`}
                      >
                        {invoice_choices[category.payment_status]}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* ----------2nd Table for pdf print------*/}
        {/* entites and paggination */}
        {totalData > 0 && (
          <div className="row pag" style={{ marginTop: "30px" }}>
            <div className="col-6 col-md-5">
              <p
                className="inter"
                style={{ color: "#AEAEB2", fontSize: "16px" }}
              >
                Total Entries:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {totalData}
                </span>
                <span className="ms-2"></span>
                Total Page:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {Math.ceil(totalData / 20)}
                </span>
              </p>
            </div>
            {/* <div className="col-12 col-md-6 d-flex paggination-button">
              <ReactPaginate
                previousLabel={<PreviousIcon />}
                nextLabel={<NextIcon />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"a"}
                nextLinkClassName={"a"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                initialPage={query?.length > 0 ? 0 : updatedPageNumber}
                forcePage={
                  query?.length > 0 || getData?.length < usersPerPage
                    ? 0
                    : updatedPageNumber
                }
              />
            </div> */}
            <div className="col-12 col-md-6 d-flex paggination-button">
              <>
                <div className="row lead_pagination bg-transparent">
                  <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                    <nav aria-label="...">
                      <ul className="pagination pagination-sm">
                        {!isLoading ? (
                          <li className="page-item ">
                            {prevUrl !== null ? (
                              <Link
                                onClick={handlePaginationPrevious}
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on first page")
                                }
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item ">
                            <Link
                              className={` ${
                                prevUrl === null ? "disabled" : ""
                              } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                              to="#"
                              tabindex="-1"
                            >
                              <PreviousIcon />
                            </Link>
                          </li>
                        )}

                        {[...Array(pageCount === null ? 1 : pageCount)].map(
                          (elem, index) => (
                            <li
                              className="page-item"
                              style={{
                                display: `${
                                  index + 1 !== pageNumber ? "none" : "block"
                                }`,
                              }}
                            >
                              {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                              {/* setPageNumber(prev=> prev - 1) */}

                              <Link
                                onClick={() =>
                                  handleChangeCurrentPage(index + 1)
                                }
                                className={`${
                                  index + 1 == pageNumber
                                    ? "paginationActive"
                                    : ""
                                } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                                to="#"
                              >
                                <span className="px-1 py-1">{pageNumber}</span>
                              </Link>
                            </li>
                          )
                        )}
                        {!isLoading ? (
                          <li className="page-item">
                            {nextUrl !== null ? (
                              <Link
                                onClick={handlePaginationNext}
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />{" "}
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on last page")
                                }
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item">
                            <Link
                              className={`${
                                nextUrl === null ? "disabled" : ""
                              } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                              to="#"
                            >
                              <NextIcon />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceList;
